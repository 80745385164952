import React from "react";
import ArticleContent from "../components/ArticleContent/ArticleContent";

function Article() {
  return (
    <>
      <ArticleContent />
    </>
  );
}

export default Article;
